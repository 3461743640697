import React, { useState } from 'react';
import Login from '../../components/Login'
import background from "../../assets/img/background.png" //Background
import overlay from "../../assets/img/overlay.png" //overlay
import logo from '../../assets/img/logo.png'; //Logo
import Footer from "../../components/Footer"

function Index(props) {
    const appName = 'Login'
    const [showLogin, setShowLogin] = useState(false)
    const deskripsi = "Selamat datang kembali di"
    const title1 = "SMP Muhammadiyah 3 Yogyakarta Blended Learning"
    const title2 = ""
    const slogan = '"Terwujudnya siswa beriman, unggul dalam berprestasi dan berwawasan lingkungan"'
    const copyright = "2022 SMP Muhammadiyah 3 Yogyakarta. - Didukung oleh"

    return (
        <>
            {  /*<div style={{position:'sticky', top: 0, zIndex: 99, backgroundColor: '#fff', padding: 8, width: '100%', textAlign: 'center', fontWeight: 'bold'}}>
        <a href="/pengumuman-ppdb/" >Informasi Pengumuman Penerimaan Siswa/i Silahkan klik disini</a>
    </div> */}
            <div className="col-12 hero-container">
                <div style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.35) 100%, rgba(255, 255, 255, 0) 100%), url(${background})` }} className="hero-background col-12"></div>
                <div className="col-12 hero" style={{ backgroundPosition: '0 100%', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(171.31deg, rgba(0, 0, 0, 0.45) 81.78%, rgba(255, 255, 255, 0) 95.16%), url(${props.overlay})` }}>
                    <div className="" >
                        <div className="text-light" data-page={appName} style={{ textAlign: 'center' }}>
                            <div className='header' style={{ paddingBottom: '18vh' }}>
                                <img className='logo' src={logo} alt={appName} style={{ width: 55, height: 70, marginLeft: '20px' }} />
                                <h3 className='logo' style={{ marginLeft: '20px', marginTop: '20px' }}>SMP Muhammadiyah 3 Yogyakarta</h3>
                                <div style={{ flex: 1 }}>
                                </div>
                                <div className='login' onClick={() => setShowLogin(true)}>
                                    Login
                                </div>
                            </div>
                            <div className="">
                                <div className="" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <img className='logo2' src={logo} alt={appName} style={{ width: 100, height: 125 }} />
                                </div>
                                <div className="" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <h2 style={{ fontSize: '200%' }}>
                                        {deskripsi}
                                    </h2>
                                </div>
                                <div className="title" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <h1 style={{ fontSize: '300%' }}>
                                        {title1}
                                    </h1>
                                </div>
                                <div className="" style={{ display: 'flex', paddingBottom: '2vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <i style={{ fontSize: '150%' }}>
                                        {slogan}
                                    </i>
                                </div>
                                <div className="" style={{ display: 'flex', paddingBottom: '5vh', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='login2' onClick={() => setShowLogin(true)}>
                                        Login
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showLogin && <div onClick={e => setShowLogin(false)} style={{ position: 'fixed', backdropFilter: 'blur(4px)', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{ maxWidth: '600px', width: '100%' }} onClick={e => e.stopPropagation()}>
                            <Login />
                        </div>
                    </div>}
                </div>
                <Footer
                    copyright={copyright}
                />
            </div>
        </>
    );
}

export default Index;